.navbar {
    background-color: #f16522 !important; 
    margin-left: 10px;
}


.navbar-light .navbar-nav .nav-link {
    color: white; 
}


.hamburger-menu {
    margin-right: 16px;
}

.active-nav-link {
    color: #404041; 
}
