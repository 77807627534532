/* .contact-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-image {
    flex: 1;
    margin-right: 20px; 
    display: flex;
}

.contact-image img {
    width: 400px;
    height: auto;
    border-radius: 15px;
}

.contact-form {
    flex: 2;
}

.contact-details {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.contact-details p {
    font-size: 16px;
    line-height: 1.6;
}

.contact-details a {
    color: #0066cc;
    text-decoration: none;
}

.contact-details a:hover {
    color: #0056b3;
}

.social-icons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 15px;
}

.social-icons a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #e4e4e4;
    color: #333;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    transition: background-color 0.3s, color 0.3s;
}

.social-icons a:hover {
    background-color: #0066cc;
    color: white;
}

.fa {
    font-size: 14px;
} */



/* Contact.css */

.contact-details {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.contact-details p {
    font-size: 16px;
    line-height: 1.6;
}

.contact-details a {
    color: #0066cc;
    text-decoration: none;
}

.contact-details a:hover {
    color: #0056b3;
}
.container {
    font-family: 'Arial', sans-serif; /* Change as per Mailchimp form's font */
}

.contact-form {
    background-color: #f8f8f8; /* Example background color */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-form .form-control {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px; /* Adjust as per Mailchimp form */
}

.contact-form .form-label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Adjust as per Mailchimp form */
}

.contact-form .btn-primary {
    background-color: #0044cc; /* Adjust as per Mailchimp form */
    border-color: #0044cc; /* Adjust as per Mailchimp form */
    padding: 10px 20px;
    border-radius: 4px;
}

.contact-form .btn-primary:hover {
    background-color: #003399; /* Adjust as per Mailchimp form */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .contact-form {
        padding: 15px;
        margin-top: 20px;
    }
    .img-fluid {
        margin-bottom: 20px;
    }
}
