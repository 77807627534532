@keyframes slideLeftToRight {
    0%, 100% {
        transform: translateX(-100%);
        opacity: 0;
    }
    10%, 90% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.shellBanner {
    background-color: #404041;
    color: #e6e7e8;
    padding: 10px 20px;
    text-align: center;
    font-size: 14px;
    position: relative;
    z-index: 1000;
    animation: slideLeftToRight 10s linear infinite;
}
