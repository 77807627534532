.servicesSection {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    padding: 73px 67px;
    background-color: #f8f9fa;
    gap: 10px; 
}

.servicesTitle {
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
    color: #333;
}

.service {
    flex: 0 0 calc(33% - 20px); /* Adjust for tablet view */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px; 
    transition: transform 0.3s ease;
    margin-bottom: 20px; /* Ensure spacing between rows */
}

.service:hover {
    transform: translateY(-5px); 
}

.serviceIcon {
    font-size: 48px;
    margin-bottom: 15px;
}

.serviceTitle {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #333;
}

/* Mobile Styles */
@media (max-width: 600px) {
    .service {
        flex: 0 0 100%; /* Full width for mobile */
        margin-bottom: 20px;
    }

    .servicesTitle {
        font-size: 24px; /* Slightly smaller font size for mobile */
    }

    .serviceIcon {
        font-size: 36px; /* Adjust icon size for smaller screens */
    }

    .serviceTitle {
        font-size: 20px;
    }
}

/* Tablet Styles */
@media (min-width: 601px) and (max-width: 1024px) {
    .service {
        flex: 0 0 calc(50% - 20px); /* Half width for tablets */
    }
}

/* Desktop Styles */
@media (min-width: 1025px) {
    .service {
        flex: 0 0 calc(25% - 20px); /* Quarter width for desktops */
    }
}
