.carousel-container {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
}

.carousel-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide {
    min-width: 100%;
    transition: all 0.5s ease;
    opacity: 0;
    transform: scale(0.8);
    display: none; /* Hide non-active slides */
}

.slide.active {
    opacity: 1;
    transform: scale(1);
    display: block; /* Show active slide */
}

.testimonial-text {
    font-style: italic;
    margin-bottom: 10px;
}

.author {
    font-weight: bold;
}

.carousel-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.prev, .next {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 10px;
}

.prev:hover, .next:hover {
    background-color: #f1f1f1;
}

/* Responsive styling */
@media (max-width: 768px) {
    .carousel-container {
        max-width: 100%;
    }

    .prev, .next {
        padding: 5px 10px;
    }
}

