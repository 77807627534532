/* PackageAndPrices.module.css */

.packageCard {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.customEnquireButton {
    background-color: #f16522; /* Original color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    padding: 10px 15px;
    text-align: center;
    display: block;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
}

.customEnquireButton:hover, .customEnquireButton:focus {
    background-color: #c44e18; /* Darker shade of orange for hover effect */
    color: black; /* Text color changes to black on hover */
}
