/* 
#3f3f3f grey
#e6e7e8 white
#f16522 orange
*/

/* Base Styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.container {
    width: 80%;
    margin: auto;
    overflow: hidden;
}

header {
    background: #f16522;
    padding: 20px 0;
    color: #e6e7e8;
}

header h1 {
    margin: 0;
    margin-left: 20px;
}

.showcase {
    display: flex;
    align-items: center;
    position: relative;
}

.showcase-img {
    position: relative;
    flex: 3;
    max-width: 100%;
    height: auto;
    background: url(./Artboard.png) no-repeat center center;
    background-size: cover;
    min-height: 438px;
    z-index: 1;
    margin-top: -1px;
}

.overlay-content {
    position: absolute;
    top: 50%;
    left: 64%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 3;
}

.overlay-content p {
    color: #e6e7e8;
    font-size: 20px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay-btn {
    display: inline-block;
    background-color: #f16522;
    color: #e6e7e8;
    padding: 10px 25px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.overlay-btn:hover {
    background-color: #e6e7e8;
    color: #f16522;
}

.showcase-content {
    flex: 1;
    padding: 40px;
    background-color: #e6e7e8;
    z-index: 2;
    min-height: 437px;
}

.showcase h1 {
    margin-top: 0;
    font-size: 55px;
    color: #404041;
}

.cta {
    display: inline-block;
    background: #e6e7e8;
    color: #404041;
    padding: 10px 30px;
    text-decoration: none;
    border-radius: 4px;
    transition: background 0.3s ease;
}

.cta:hover {
    background: #404041;
    color: #e6e7e8;
}

/* Responsive Styles */
@media only screen and (max-width: 600px) {
    .showcase {
        flex-direction: column;
    }

    .showcase-img, .showcase-content {
        width: 100%;
        flex: none;
    }

    .overlay-content {
        left: 50%;
        top: 30%;
    }

    .overlay-content p {
        font-size: 16px;
    }

    .showcase h1 {
        font-size: 32px;
    }

    .cta, .overlay-btn {
        padding: 15px 35px;
        font-size: 18px;
    }
}

/* Tablet styles */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .showcase {
        flex-direction: row;
    }

    .showcase-img {
        width: 60%;
    }

    .showcase-content {
        width: 53%;
        flex: 1 1;
        padding: 17px;
    }

    .overlay-content {
        left: 60%;
        top: 50%;
    }
}

/* Desktop styles */
@media only screen and (min-width: 1025px) {
    /* Your default styles will apply */
}
@media only screen and (max-width: 600px) {
    .overlay-content p {
        font-size: 16px;
    }

    .showcase h1 {
        font-size: 32px;
    }
}
