.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.about-header {
    text-align: center;
}

.about-content {
    margin: 0 auto;
    max-width: 700px;
    padding: 20px;
}
h2 {
    color: #333;
}
strong {
    color: #007bff;
}

.about-image {
    width: 150px;
    height: auto;
    border-radius: 20%;
    margin-top: 20px;
}

.about-content, .testimonials-section {
    margin-top: 40px;
}

.testimonials-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.social-media-links {
    text-align: center;
    margin-top: 30px;
}


.social-media-links {
    text-align: center;
    margin-top: 30px;
}

.social-media-links a {
    margin: 0 10px;
    color: #333; /* or any color you prefer */
    font-size: 24px; /* Adjust size as needed */
}

.social-media-links a:hover {
    color: #007bff; /* or any hover color you prefer */
}
