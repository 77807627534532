.faq-item {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f2f2f2;
    transition: background-color 0.3s;
}

.faq-item:hover {
    background-color: #e9e9e9;
}

.faq-answer {
    padding-left: 20px;
    color: #333;
}

@media (max-width: 768px) {
    .faq-item p {
        padding-left: 8px;
        font-size: 14px;
    }
}
